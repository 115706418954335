import { Routes, Route, useNavigate } from 'react-router-dom';
import '../src/App.css';
import React, { useEffect, useState } from 'react';
import Auth from './auth/auth';
import { Login } from './auth/Login';
import ProtectedRoute from './auth/util/protectedRoute';
import NotFound from './auth/notFound';
import Home from './menu/home';
import { CompnySignup } from './auth/signup';
import ActivationAccount from './auth/activation';
import { ForgotPassword } from './auth/changePassword';
import { ResetEmail } from './auth/resetUserEmail';
import VerifyEmail from './auth/verifyEmail';
import { useSelector } from 'react-redux';
import KeyIcon from '@mui/icons-material/Key';
import { selectUserType } from './utility/Redux/profile';
import ApprovePost from './menu/approvePost';
import CreatePost from './menu/createPost';
import UpdateKey from './menu/updateKey';
import TempleMaster from './menu/templeMaster';
import AddressMaster from './menu/addressMaster';
import AddressReport from './menu/adressReport';
import TempleReport from './menu/templeReport';
import Masters from './masters/dashBoard';
import ItemMaster from './masters/ItemMaster/itemMaster';
import CatogeryMaster from './masters/catogeryMaster/catogeryMaster';
import SectionMaster from './masters/SectionMaster/section';
import ItemList from './masters/ItemMaster/list';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const checkUserToken = () => {
    const userToken = localStorage.getItem('user');
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false);
    }
    setIsLoggedIn(true);
  };
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  const userType = useSelector(selectUserType);

  return (
    <>
      <Routes>
        <Route path="/auth" element={<Auth />}>
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<CompnySignup />} />
          <Route path="reset_password_email" element={<ResetEmail />} />
        </Route>

        <Route path="*" element={<NotFound />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        >
          <Route
            path={`approve_post`}
            exact
            element={
              <ProtectedRoute>
                <ApprovePost />
              </ProtectedRoute>
            }
          />

          <Route
            path={`item_list`}
            exact
            element={
              <ProtectedRoute>
                <ItemList />
              </ProtectedRoute>
            }
          />
          <Route
            path={`masters`}
            exact
            element={
              <ProtectedRoute>
                <Masters />
              </ProtectedRoute>
            }
          />
          <Route
            path={`Catogery_Master`}
            exact
            element={
              <ProtectedRoute>
                <CatogeryMaster />
              </ProtectedRoute>
            }
          />
          <Route
            path={`create_post`}
            exact
            element={
              <ProtectedRoute>
                <CreatePost />
              </ProtectedRoute>
            }
          />
          <Route
            path={`item_master`}
            exact
            element={
              <ProtectedRoute>
                <ItemMaster />
              </ProtectedRoute>
            }
          />

          <Route
            path={`section_master`}
            exact
            element={
              <ProtectedRoute>
                <SectionMaster />
              </ProtectedRoute>
            }
          />
          <Route
            path={`update_key`}
            exact
            element={
              <ProtectedRoute>
                <UpdateKey />
              </ProtectedRoute>
            }
          />
          <Route
            path="address_master"
            element={
              <ProtectedRoute>
                <AddressMaster />
              </ProtectedRoute>
            }
          />
          <Route
            path="address_report"
            element={
              <ProtectedRoute>
                <AddressReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="temple_report"
            element={
              <ProtectedRoute>
                <TempleReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="temple_master"
            element={
              <ProtectedRoute>
                <TempleMaster />
              </ProtectedRoute>
            }
          />
          {/* home outlet routes here */}
        </Route>
        <Route path="/changeUserPassword" element={<ForgotPassword />} />
        <Route path="/activateUser" element={<ActivationAccount />} />
        <Route path="/emailVerified" element={<VerifyEmail />} />
      </Routes>
    </>
  );
}

export default App;
