import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';

const prefixEncrypted = localStorage.getItem('prefix');
const userTypeEncrypted = localStorage.getItem('userType');
const appIdEncrypted = localStorage.getItem('app');
const pageIdEncrypted = localStorage.getItem('page');

const fb_id = localStorage.getItem('stationId');
const company = localStorage.getItem('company');
const userName = localStorage.getItem('userName');
const stationName = localStorage.getItem('stationName');
const companyAddress = JSON.parse(localStorage.getItem('companyDetails'));
const secretPass = 'kasdaksjfdsaask';
const getPrefix = () => {
  if (prefixEncrypted) {
    const bytes = CryptoJS.AES.decrypt(prefixEncrypted, secretPass);
    if (bytes.sigBytes < 0) {
      localStorage.removeItem('prefix');
      localStorage.removeItem('expiresAt');
    } else {
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      var prefix = data;

      return prefix;
    }
  }

  return null;
};

const getAppid = () => {
  if (appIdEncrypted) {
    const bytes = CryptoJS.AES.decrypt(appIdEncrypted, secretPass);
    if (bytes.sigBytes < 0) {
      localStorage.removeItem('prefix');
      localStorage.removeItem('expiresAt');
    } else {
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      const appid = data;

      return appid;
    }
  }

  return null;
};

const getPageid = () => {
  if (pageIdEncrypted) {
    const bytes = CryptoJS.AES.decrypt(pageIdEncrypted, secretPass);
    if (bytes.sigBytes < 0) {
      localStorage.removeItem('prefix');
      localStorage.removeItem('expiresAt');
    } else {
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      const pageId = data;

      return pageId;
    }
  }

  return null;
};

const getUserType = () => {
  if (userTypeEncrypted) {
    const bytes = CryptoJS.AES.decrypt(userTypeEncrypted, secretPass);
    if (bytes.sigBytes < 0) {
      localStorage.removeItem('prefix');
      localStorage.removeItem('expiresAt');
    } else {
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      var userType = data;

      return userType;
    }
  }

  return null;
};

const GuestDetails = () => {
  const Guest = localStorage.getItem('BillingGuestDetails');
  if (Guest) {
    return JSON.parse(Guest);
  } else {
    return {
      guestName: '',
      guestMobile: '',
      guestEmail: '',
    };
  }
};

const profileIntialState = {
  userName: userName,
  prefix: getPrefix(),
  width: window.innerWidth,
  todayIssueStatus: false,
  companyAddress: companyAddress,
  userType: getUserType(),
  companyCity: '',
  billingGuest: GuestDetails(),
  billIngAccount: {
    accNumber: '',
    accMobile: '',
    accName: '',
  },
  pageId: getPageid(),
  appId: getAppid(),
  profileDefaultSetting: '',
  fb_id: fb_id,
  stationName: stationName,
  latitude: '',
  longitude: '',
};

export const Profile = createSlice({
  name: 'profile',
  initialState: profileIntialState,
  reducers: {
    setUserName(state, action) {
      state.userName = action.payload;
      // console.log('redux login updated '+ action.payload)
    },
    setWidth(state, action) {
      state.width = action.payload;
    },
    setAppId(state, action) {
      state.appId = action.payload;
    },
    setPageId(state, action) {
      state.pageId = action.payload;
    },
    setdefaultProfileSetting(state, action) {
      state.profileDefaultSetting = action.payload;
    },
    setTodayIssueStatus(state, action) {
      state.todayIssueStatus = action.payload;
    },
    setlatitudeRedux(state, action) {
      state.latitude = action.payload;
    },
    setlongitudeRedux(state, action) {
      state.longitude = action.payload;
    },
    setUserType(state, action) {
      state.userType = action.payload;
    },
    setCompanyAddress(state, action) {
      state.companyAddress = action.payload;
    },
    setBillingAccountDetails(state, action) {
      state.billIngAccount = action.payload;
    },
    setStationName(state, action) {
      state.stationName = action.payload;
    },
    setPrefix(state, action) {
      state.prefix = action.payload;
      // console.log('redux login updated '+ action.payload)
    },
    setStationID(state, action) {
      state.fb_id = action.payload;
    },
    setBillingGuestDetails(state, action) {
      state.billingGuest = { ...action.payload };
      localStorage.setItem(
        'BillingGuestDetails',
        JSON.stringify({ ...action.payload })
      );
    },
    setCompany(state, action) {
      state.company = action.payload;
      // console.log('redux login updated '+ action.payload)
    },
    resetProfile(state, action) {
      state = profileIntialState;
    },
  },
});

export default Profile.reducer;

export const {
  setBillingGuestDetails,
  setUserName,
  setAppId,
  setWidth,
  setPageId,
  resetProfile,
  setTodayIssueStatus,
  setCompany,
  setlatitudeRedux,
  setlongitudeRedux,
  setPrefix,
  setUserType,
  setdefaultProfileSetting,
  setCompanyAddress,
  setStationName,
  setStationID,
  setBillingAccountDetails,
} = Profile.actions;

export const selectUserName = (state) => state.profile.userName;
export const selectCompany = (state) => state.profile.company;
export const selectPrefix = (state) => state.profile.prefix;
export const selectGuestDetails = (state) => state.profile.billingGuest;
export const selectStationId = (state) => state.profile.fb_id;
export const selectStationName = (state) => state.profile.stationName;
export const selectAccountDetails = (state) => state.profile.billIngAccount;
export const selectCompanyAddress = (state) => state.profile.companyAddress;
export const selectUserType = (state) => state.profile.userType;
export const selectLatitude = (state) => state.profile.latitude;
export const selectLongitude = (state) => state.profile.longitude;
export const selectDefultProfileSetting = (state) =>
  state.profile.profileDefaultSetting;
export const selectTodayIssueStatus = (state) => state.profile.todayIssueStatus;
export const selectPageId = (state) => state.profile.pageId;
export const selectAppId = (state) => state.profile.appId;
export const selectWidth = (state) => state.profile.width;
