import React from 'react';
import {
  AppBar,
  Drawer,
  DrawerHeader,
} from '../../../utility/component/compHome';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';

import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import CreateIcon from '@mui/icons-material/Create';
import { Close, ExitToApp, Logout } from '@mui/icons-material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import {
  selectAppId,
  selectCompanyAddress,
  selectUserName,
  selectUserType,
} from '../../Redux/profile';
import { useDispatch, useSelector } from 'react-redux';

import {
  setALertStatus,
  setAlertMsg,
  setCustomeTwoButtomAlert,
} from '../../../utility/Redux/modal';
import { useState } from 'react';
import useCookies from 'react-cookie/cjs/useCookies';

import { useLocation, useNavigate } from 'react-router-dom';
import { selectDeveloperMode } from '../../Redux/security';

const AppBarDesign = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['myCookie']);
  const [MobileMenu, setMobileMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const company = useSelector(selectCompanyAddress);
  const userType = useSelector(selectUserType);
  const active = cookies.myCookie ? cookies.myCookie.activeOption : null;
  const userName = useSelector(selectUserName);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState('create_post');
  const [activeOption, setActiveOption] = useState(
    active ? active : 'Dashboard'
  );

  const { companyName } = company;
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const appId = useSelector(selectAppId);
  const isdevloper = useSelector(selectDeveloperMode);
  const drawerList =
    appId === '462207993266497'
      ? ['Approve Post', 'Masters']
      : [
          'Approve Post',
          'Address Master',
          'Temple Master',
          'Address Report',
          'Temple Report',
        ];
  if (isdevloper) {
    drawerList.push('Update Key');
  }
  return (
    <div>
      <AppBar
        elevation={2}
        position="fixed"
        open={open}
        style={{ justifyItems: 'start' }}
      >
        <Toolbar
          style={{ justifyItems: 'start', height: '60px' }}
          className={'t:-mt-2 -mt-1'}
        >
          {window.innerWidth <= 600 ? (
            <MenuIcon
              sx={{ marginRight: 1 }}
              onClick={() => setMobileMenu(!MobileMenu)}
            />
          ) : location.pathname === '/' ? (
            <MenuIcon
              color="inherit"
              aria-label="open drawer"
              edge="start"
              sx={{
                marginRight: 1,
                marginLeft: -1,

                ...(open && { display: 'none' }),
              }}
              onClick={handleDrawerOpen}
            />
          ) : (
            <MenuIcon
              color="inherit"
              aria-label="open drawer"
              edge="start"
              sx={{
                marginRight: 1,
                marginLeft: -1,

                ...(open && { display: 'none' }),
              }}
              onClick={handleDrawerOpen}
            />
          )}

          {/* </IconButton> */}

          <div className="   flex grow justify-between items-center  w-full   ">
            <div className="flex">
              <div
                className={`${open ? 'hidden' : 'flex items-center w-160  '} `}
              >
                <img
                  src={require('../../../utility/images/GOROUND2.png')}
                  alt=""
                  style={{ width: '35px', height: 'auto' }}
                />
                <img
                  src={require('../../../utility/images/GO92X12.png')}
                  alt=""
                  className=" ml:w-24 h-auto ms:w-16 mm:w-24  "
                />
              </div>

              <div className="ms:hidden mm:flex">
                <div
                  className={`mt-2 bg-[#f5874f]    ${
                    open ? 'hidden ' : null
                  } t:ml-5  ml-2 ml:ml-2  `}
                  style={{ width: '0.5px', height: '54px' }}
                />

                {open ? (
                  <div className="-ml-4 z-80 ">
                    <Close color="gray" onClick={() => setOpen(false)} />
                  </div>
                ) : null}

                <div
                  className={`  justify-between  items-center ml-2 hidden t:flex`}
                >
                  <div
                    style={{ fontSize: 16 }}
                    className={'tracking-[.1em]   '}
                  >
                    {location.pathname === '/' ? (
                      companyName
                    ) : (
                      <div className="flex w-32 capitalize whitespace-nowrap  ">
                        {' '}
                        {location.pathname
                          .substring(1)
                          .replace('_', ' ')
                          .replace('_', ' ')
                          .replace('_', ' ')}{' '}
                      </div>
                    )}{' '}
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{ fontSize: 16 }}
              className={'tracking-[.1em] flex items-center ml-4'}
            >
              {userType}
              <div
                className="hidden md:flex"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  maxWidth: 120,
                  textOverflow: 'ellipsis',
                }}
              >
                <span className="pl-2 capitalize   ">
                  {' '}
                  {'|' + ' ' + userName}
                </span>{' '}
              </div>
              <div
                style={{ width: '1px', height: 20 }}
                className=" bg-orange mr-3 ml-2  "
              />
              <div className={`flex items-center text-orange  `}>
                <button
                  onClick={() => {
                    dispatch(setCustomeTwoButtomAlert(true));
                  }}
                >
                  <ExitToApp />
                </button>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        className={`${MobileMenu ? ' p:flex' : 'ms:hidden p:flex'} bg-red-200`}
      >
        {/* <div className="bg-brown">
          <img src={require('../../images/hori_logo.jpg')} />
        </div> */}

        {open && (
          <div className="bg-[#383535]  ">
            <DrawerHeader className="flex flex-row  justify-center items-center border-b  h-[209px] ">
              <div className="flex grow"></div>
              <div className={`${!open ? 'hidden' : null}    `}>
                <div className="flex flex-col items-center ">
                  <img
                    alt=""
                    style={{
                      width: '130px',
                    }}
                    src={require('../../../utility/images/GOROUND2.png')}
                    className="h-auto "
                  />
                  <img
                    alt=""
                    src={require('../../../utility/images/GO92X12.png')}
                    className="h-auto w-24 "
                  />
                  <div
                    style={{ fontSize: 9, marginTop: -1 }}
                    className=" flex justify-center   text-gray-200"
                  >
                    Since 1994
                  </div>
                </div>
              </div>
              <div className="flex grow"></div>
            </DrawerHeader>
          </div>
        )}

        <div
          style={{
            fontsize: '13px',
          }}
          className={`bg-[#383535] ${open ? ' pt-[10px]' : ' pt-[60px]'}`}
        >
          {drawerList.map((text, index) => (
            <ListItem
              onClick={() => {
                {
                  text === 'Approve Post' && navigate('approve_post');
                }

                {
                  text === 'Update Key' && navigate('update_key');
                }
                {
                  text === 'Address Master' && navigate('address_master');
                }
                {
                  text === 'Temple Master' && navigate('temple_master');
                }
                {
                  text === 'Address Report' && navigate('address_report');
                }
                {
                  text === 'Temple Report' && navigate('temple_report');
                }
                {
                  text === 'Masters' && navigate('masters');
                }
                setSelectedMenu(text);
                setMobileMenu(false);
              }}
              sx={
                selectedMenu === text
                  ? { color: '#ff6f0f', fontSize: '8px' }
                  : { color: '#f2f4f7', fontSize: '8px' }
              }
              key={text}
              disablePadding
            >
              <ListItemButton
                sx={
                  selectedMenu === text
                    ? { color: '#ff6f0f' }
                    : { color: '#f2f4f7' }
                }
              >
                <ListItemIcon
                  sx={
                    selectedMenu === text
                      ? {
                          color: '#ff6f0f',
                          marginRight: '-25px',
                        }
                      : {
                          color: '#f2f4f7',

                          marginRight: '-25px',
                        }
                  }
                >
                  {text === 'Approve Post' && (
                    <div
                      style={{
                        fontSize: '14px',
                      }}
                    >
                      AP
                    </div>
                  )}
                  {text === 'Update Key' && (
                    <VpnKeyIcon
                      sx={{
                        fontSize: '20px',
                      }}
                    />
                  )}

                  {text === 'Address Master' && (
                    <div
                      style={{
                        fontSize: '14px',
                      }}
                    >
                      {' '}
                      AM
                    </div>
                  )}

                  {text === 'Masters' && (
                    <div
                      style={{
                        fontSize: '14px',
                      }}
                    >
                      {' '}
                      MS
                    </div>
                  )}
                  {text === 'Temple Master' && (
                    <div
                      style={{
                        fontSize: '14px',
                      }}
                    >
                      TM
                    </div>
                  )}
                  {text === 'Address Report' && (
                    <div
                      style={{
                        fontSize: '14px',
                      }}
                    >
                      AR
                    </div>
                  )}
                  {text === 'Temple Report' && (
                    <div
                      style={{
                        fontSize: '14px',
                      }}
                    >
                      TR
                    </div>
                  )}
                </ListItemIcon>
                {/* <div
                  className="py-1 -pl-3"
                  style={{
                    fontSize: '14px',
                  }}
                >
                  {text}
                </div> */}
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </div>

        <div className="h-full bg-[#383535] flex flex-col justify-end pb-0 items-center ">
          <div style={{ height: '0.5px' }} className=" bg-[#a9a9a9]  w-11/12" />

          <ListItem disablePadding>
            <ListItemButton
              style={{ paddingLeft: 12 }}
              onClick={() => {
                dispatch(
                  setCustomeTwoButtomAlert({
                    status: true,
                  })
                );
                setMobileMenu(false);
              }}
            >
              <ListItemIcon
                onClick={() => {
                  dispatch(
                    setCustomeTwoButtomAlert({
                      status: true,
                    })
                  );
                  setMobileMenu(false);
                }}
              >
                <Logout
                  style={{ fontSize: 24, color: '#f5874f' }}
                  className="text-[#a9a9a9]"
                />
              </ListItemIcon>
              <ListItemText
                primary="Logout"
                className="text-[#a9a9a9]"
                style={{ marginLeft: -18 }}
                primaryTypographyProps={{
                  variant: 'subtitle',
                  style: { fontSize: 16 },
                }}
              />
            </ListItemButton>
          </ListItem>
        </div>
      </Drawer>
    </div>
  );
};

export default AppBarDesign;
